<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <div>
      <div class="d-flex justify-content-end">
        <b-button-group>
          <b-button @click="newFolder" size="sm" variant="outline-primary">
            New Folder
          </b-button>
          <b-button @click="uploadFiles" size="sm" variant="outline-primary">
            Upload Files
          </b-button>
        </b-button-group>
      </div>

      <div id="breadcrumb-alignment" class="mb-1">
        <div id="menu" class="d-flex flex-column align-items-left">
          <b-breadcrumb>
            <b-breadcrumb-item v-for="(item, i) in folderTemp" :key="i" :class="item.active == true ? 'active' : ''" @click="menuClick(i, item)">
              <feather-icon icon="HomeIcon" class="mr-50 mb-25" v-if="i == 0" />
              {{ item.name }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>

      <div v-if="foldersData.length > 0">
        <vue-good-table :columns="columns" :rows="foldersData" styleClass="vgt-table condensed">
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.field === 'type'" class="text-nowrap">
              <!-- <img :src="props.row.type == 'Folder' ? '/icons/folder.png' : '/icons/pdf.png'" :alt="props.row.type" class="custom-card-image" /> -->

              <img :src="getTypeIcon(props.row.type, props.row.name)" :alt="props.row.type" class="custom-card-image" />
            </span>

            <span v-else-if="props.column.field === 'name'">
              <div class="custom-card-name" @click="itemClick(props.row)" v-b-tooltip.hover.bottom="props.row.name">{{ props.row.name }}</div>
              <div v-if="props.row.fileDetails">
                <small>{{ props.row.fileDetails.comment }}</small>
              </div>
            </span>

            <span v-else-if="props.column.field === 'fileSystemInfo.createdDateTime'" class="text-nowrap">
              <span>{{ formatDateTime(props.row.fileSystemInfo.createdDateTime) }}</span>
            </span>

            <span v-else-if="props.column.field === 'createdBy'" class="text-nowrap">
              <span>{{ props.row.createdBy }}</span>
            </span>

            <span v-else-if="props.column.field === 'fileSystemInfo.lastModifiedDateTime'" class="text-nowrap">
              <span>{{ formatDateTime(props.row.fileSystemInfo.lastModifiedDateTime) }}</span>
            </span>

            <span v-else-if="props.column.field === 'lastModifiedBy'" class="text-nowrap">
              <span>{{ props.row.lastModifiedBy }}</span>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'" class="text-nowrap">
              <span>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                  </template>
                  <b-dropdown-item @click="downloadDocument(props.row)">
                    <feather-icon icon="DownloadIcon" class="mr-50" />
                    <span>Download</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="renameDocument(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteDocument(props.row)" v-if="userData.role == 'supermanager'">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete </span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <!-- <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span> -->
          </template>
        </vue-good-table>
      </div>

      <div class="mt-5 no-file-message" v-else>
        This folder is empty
      </div>

      <div class="mt-1">&nbsp;</div>
      <!-- Add New Folder -->
      <b-modal id="modal-new-folder" @ok="handleFolderOk" @show="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Save Folder" centered title="Add New Folder">
        <validation-observer ref="folderRules">
          <b-form>
            <b-form-group>
              <label for="folderName">Folder Name:</label>
              <validation-provider name="Folder Name" #default="{ errors }" rules="required">
                <b-form-input id="folderName" type="text" v-model="modalFolderName" placeholder="Folder Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-modal>

      <!-- Add New Files -->
      <b-modal id="modal-new-file" @ok="handleFileOk" @show="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Save File" centered title="Add New Files">
        <validation-observer ref="fileRules">
          <b-form>
            <b-form-group>
              <validation-provider name="File" #default="{ errors }" rules="required">
                <b-form-file type="file" v-model="modalFiles" multiple placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <b-form-textarea type="file" v-model="modalComment" multiple placeholder="You can add additional notes about the file..." />
            </b-form-group>
          </b-form>
        </validation-observer>

        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>

      <!-- Edit Folder  -->

      <b-modal id="modal-edit-folder" @ok="handleFolderUpdate" @hidden="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Update Folder" centered title="Edit Folder">
        <validation-observer ref="folderRules">
          <b-form>
            <b-form-group>
              <label for="folderName">Folder Name:</label>
              <validation-provider name="Folder Name" #default="{ errors }" rules="required">
                <b-form-input id="folderName" type="text" v-model="modalFolderName" placeholder="Folder Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-modal>

      <!-- Edit File Name -->
      <b-modal id="modal-edit-file" @ok="handleFileUpdate" @show="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Update File" centered title="Edit New Files">
        <validation-observer ref="fileRules">
          <b-form>
            <b-form-group>
              <validation-provider name="File Name" #default="{ errors }" rules="required">
                <div class="d-flex">
                  <b-form-input id="fileName" type="text" v-model="modalFileName" placeholder="File Name" />
                  <span class="ml-25 mt-1 text-muted" style="font-weight: bold;" v-if="tempExtension">.{{ tempExtension }}</span>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay } from 'bootstrap-vue';
import store from '@/store';
import vSelect from 'vue-select';
import axiosIns from '@/libs/axios';
import VueContext from 'vue-context';
import router from '@/router';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BBreadcrumb } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import filesStoreModule from './filesStoreModule';
import { onUnmounted } from '@vue/composition-api';
import { required, email } from '@validations';
import { formatTimeAgo, formatDateTime } from '../helpers';
import { VueGoodTable } from 'vue-good-table';

import 'vue-good-table/dist/vue-good-table.css';

export default {
  components: {
    BOverlay,
    VueGoodTable,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    VueContext,
    BBreadcrumb,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  setup() {
    const FILES_APP_STORE_MODULE_NAME = 'files';
    // Register module
    if (!store.hasModule(FILES_APP_STORE_MODULE_NAME)) store.registerModule(FILES_APP_STORE_MODULE_NAME, filesStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FILES_APP_STORE_MODULE_NAME)) store.unregisterModule(FILES_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      rightClickData: [],
      loading: false,
      fileURL: store.state.app.fileURL,
      rows: [],
      searchTerm: '',
      folderId: 0,
      modalFolderName: null,
      modalFiles: null,
      modalComment: null,
      modalFile: [],
      percentCompleted: 0,
      userData: JSON.parse(localStorage.getItem('userData')),
      directoryPath: '',
      required,
      email,
      folderTemp: [],
      foldersData: [],
      tempExtension: null,
      columns: [
        {
          label: '#',
          field: 'type',
          width: '5%',
        },
        {
          label: 'Name',
          field: 'name',
          width: '30%',
        },
        {
          label: 'Created',
          field: 'fileSystemInfo.createdDateTime',
          width: '15%',
        },
        {
          label: 'Created By',
          field: 'createdBy',
          width: '15%',
        },
        {
          label: 'Modified',
          field: 'fileSystemInfo.lastModifiedDateTime',
          width: '15%',
        },
        {
          label: 'Modified By',
          field: 'lastModifiedBy',
          width: '15%',
        },

        {
          label: 'Action',
          field: 'action',
          sortable: false,
          width: '5%',
        },
      ],
    };
  },

  created() {
    this.fetchFolders();
  },

  methods: {
    formatTimeAgo,
    formatDateTime,

    getTypeIcon(type, name) {
      return type === 'Folder' ? '/icons/folder.png' : `/icons/${this.getFileExtension(name)}.png`;
    },
    getFileExtension(filename) {
      const parts = filename.split('.');
      return parts.length > 1 ? parts[parts.length - 1] : '';
    },

    menuClick(index, val) {
      this.folderId = val.id;

      if (val.active == false) {
        if (val.id == 0) {
          this.fetchFolders();
        } else {
          this.openFolder(val);
          this.folderTemp.splice(index);
        }
      }
    },

    fetchFolders() {
      this.loading = true;
      store
        .dispatch('files/getProjectFolders')
        .then((res) => {
          this.folderTemp = [
            {
              name: 'Main',
              id: 0,
              active: true,
            },
          ];
          this.foldersData = res.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openFolder(val) {
      this.loading = true;
      this.folderId = val.id;
      store
        .dispatch('files/openProjectFolder', { id: val.id })
        .then((res) => {
          this.foldersData = res.data;
          this.folderTemp.forEach((element) => {
            element.active = false;
          });

          this.folderTemp.push({
            id: val.id,
            name: val.name,
            active: true,
          });

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    newFolder() {
      this.$bvModal.show('modal-new-folder');
    },

    uploadFiles(val) {
      this.$bvModal.show('modal-new-file');
    },

    itemClick(val) {
      if (val.type == 'Folder') {
        this.openFolder(val);
      } else {
        this.downloadDocument(val);
      }
    },

    handleFolderOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      var folderIsExist = this.foldersData.some((val) => val.name === this.modalFolderName);

      if (!folderIsExist) {
        this.$refs.folderRules.validate().then((success) => {
          if (success) {
            var tempData = {
              folderName: this.modalFolderName,
              topMenuId: this.folderId,
              status: 'A',
            };

            this.loading = true;
            this.$bvModal.hide('modal-new-folder');
            store
              .dispatch('files/saveProjectFolder', tempData)
              .then((res) => {
                this.foldersData = res.data;
                this.loading = false;

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Folder has been created',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Folder Create Error',
            text: `A folder with the name "${this.modalFolderName}" already exists. Please choose a different name.`,
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        });
      }
    },

    handleFolderUpdate(bvModalEvent) {
      // Prevent modal from closing

      bvModalEvent.preventDefault();

      var data = {
        id: this.selectFolderId,
        folderName: this.modalFolderName,
        topMenuId: this.folderId,
        status: 'A',
      };
      // Trigger submit handler
      this.modalFolderUpdate(data);
    },

    handleFileUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      var data = {
        id: this.selectFileId,
        fileName: this.modalFileName + '.' + this.tempExtension,
        status: 'A',
      };
      // Trigger submit handler

      this.modalFileUpdate(data);
    },

    handleFileOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      const filesExist = this.foldersData.some((folder) => this.modalFiles.some((file) => folder.name === file.name));

      if (filesExist != true) {
        this.$refs.fileRules.validate().then((success) => {
          if (success) {
            if (!this.modalFiles || this.modalFiles.length === 0) {
              console.error('Please choose a file.');
              return;
            }
            var tempFormData = new FormData();

            this.modalFiles.forEach((element) => {
              tempFormData.append('File[]', element);
              tempFormData.append('folderId', this.folderId);
              if (this.modalComment !== null && this.modalComment !== undefined) {
                tempFormData.append('comment', this.modalComment);
              }
            });

            this.loading = true;
            this.$bvModal.hide('modal-new-file');

            axiosIns
              .post('uploadProjectFile', tempFormData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                  this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                },
              })
              .then((res) => {
                this.foldersData = res.data;
                this.loading = false;

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ File has been updated',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });
              })
              .catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              })
              .finally(() => {
                this.modalFiles = [];
                this.modalComment = null;
                this.percentCompleted = 0;
                this.modalShow = false;
              });
          }
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File Upload Error',
            text: 'One or more files already exist in the selected folders.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },

    modalFileOk(tempData) {
      store
        .dispatch('files/saveProjectFile', tempData)
        .then((res) => {
          this.foldersData = res.data;

          this.$bvModal.hide('modal-new-file');
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ File has been uploaded',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    modalFolderUpdate(data) {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-edit-folder');
      });

      this.loading = true;

      store
        .dispatch('files/renameDocument', { id: data.id, topFolderId: this.folderId, newFileName: data.folderName })
        .then((res) => {
          this.foldersData = res.data;
          this.modalFolderName = null;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    modalFileUpdate(data) {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-edit-file');
      });

      this.loading = true;

      store
        .dispatch('files/renameDocument', { id: data.id, topFolderId: this.folderId, newFileName: data.fileName })
        .then((res) => {
          this.foldersData = res.data;
          this.modalFileName = null;
          this.tempExtension = null;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    resetModal() {
      this.modalFolderName = null;
      this.modalFiles = null;
      this.percentCompleted = 0;
      this.modalFile = [];
    },

    deleteDocument(val) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this document?', {
          size: 'sm',
          title: 'Confirm Deletion',
          okVariant: 'primary',
          headerBgVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;

            axiosIns
              .post(`deleteDocument`, { id: val.id, topFolderId: this.folderId })
              .then((res) => {
                this.foldersData = res.data;

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Document has been deleted',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });
                this.loading = false;
              })
              .catch((error) => {
                this.loading = false;

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        });
    },

    renameDocument(val) {
      this.tempExtension = null;

      if (val.type == 'Folder') {
        this.modalFolderName = val.name;
        this.selectFolderId = val.id;
        this.$bvModal.show('modal-edit-folder');
      } else {
        this.modalFileName = this.removeFileExtension(val.name);
        this.selectFileId = val.id;
        this.$bvModal.show('modal-edit-file');
      }
    },

    removeFileExtension(fileName) {
      let parts = fileName.split('.');

      if (parts.length > 1) {
        this.tempExtension = parts.pop();
      }

      let newFileName = parts.join('.');

      return newFileName;
    },

    downloadDocument(val) {
      this.loading = true;

      axiosIns
        .get(`/downloadDocuments/${val.id}/${val.type}/${Date.now()}`, {
          responseType: 'blob',
        })
        .then((res) => {
          const blob = new Blob([res.data], { type: res.headers['content-type'] });

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', val.name);
          document.body.appendChild(link);
          link.click();

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.active a {
  color: #6e6b7b;
  cursor: auto;
  text-decoration: none;
}

.no-file-message {
  font-size: 24px;
  color: #d8d6de;
  text-align: center;
  margin-top: 20px;
}

.custom-card-image {
  width: 32px;
  height: 32px;
}

.custom-card-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  display: inline-block;
}

.custom-card-name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.table.vgt-table td {
  vertical-align: middle;
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '~vue-context/dist/css/vue-context.css';
</style>
