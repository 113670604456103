import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFiles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('fetchFiles', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    openProjectFolder(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('openProjectFolder', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    dropdownUserAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('dropdownUserAll', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    dropdownCompanyAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('dropdownCompanyAll', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getProjectFolders(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getProjectFolders', { params: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveProjectFolder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveProjectFolder', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteDocument(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('deleteDocument', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    renameDocument(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('renameDocument', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveProjectFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveProjectFile', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put('updateProjectFile', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectFolder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put('updateProjectFolder', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
